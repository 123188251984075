import { keyBy } from 'lodash-es';
import {
  AssetRegistryAssetMetadata,
  QueryOptional,
  QueryServiceReturnType,
} from '../../../../services';
import { transformToAsset } from '../../../token';
import { Pool } from '../../Pool';
import { PromotedPoolWeightData } from '../../promoted/metadata/services/PromotedPoolMetadataService';
import { BN } from '@polkadot/util';
import { getAllPools } from '../services/AllPoolsService';
import { Mangata } from 'gasp-sdk';

export enum PoolType {
  Xyk = 'Xyk',
  StableSwap = 'StableSwap',
}

export interface AllPoolsQueryData {
  byLPId: Record<string, Pool>;
  baseList: Pool[];
  list: Pool[];
  byAssetPair: Record<string, Pool[]>;
  byId: Record<string, Pool>;
  byAdjacentId: Record<string, Pool[]>;
}

export const getInvertedPool = (pool: Pool) => ({
  ...pool,
  secondAsset: pool.firstAsset,
  secondTokenId: pool.firstTokenId,
  secondTokenAmount: pool.firstTokenAmount,
  secondTokenRatio: pool.firstTokenRatio,
  firstAsset: pool.secondAsset,
  firstTokenId: pool.secondTokenId,
  firstTokenAmount: pool.secondTokenAmount,
  firstTokenRatio: pool.secondTokenRatio,
  symbols: [pool.secondAsset.symbol, pool.firstAsset.symbol],
  assetId: `${pool.secondTokenId}-${pool.firstTokenId}`,
  isInverted: !pool.isInverted,
});

export const transformAllPools =
  (
    assets: AssetRegistryAssetMetadata[] | null | undefined,
    isFirstPoolTokenLeader: (pool: Pool) => boolean,
    promotedPoolsData: QueryOptional<PromotedPoolWeightData>,
  ) =>
  (pools: QueryServiceReturnType<typeof getAllPools>): AllPoolsQueryData | null => {
    if (!pools || !assets || !promotedPoolsData) {
      return null;
    }

    const basePools = pools
      .map((data) => {
        if (!data.reserves) {
          return null;
        }
        const liquidityTokenId = data.lpTokenId.toString();
        const pool = {
          id: data.poolId.toString(),
          liquidityTokenId,
          type: data.kind.toString(),
          firstTokenId: data.assets[0].toString(),
          secondTokenId: data.assets[1].toString(),
          isPromoted: !!promotedPoolsData[liquidityTokenId],
          firstTokenAmount: new BN(data.reserves?.[0].toString()),
          secondTokenAmount: new BN(data.reserves?.[1].toString()),
        };
        const firstNativeAsset = assets.find((asset) => asset.id === pool.firstTokenId);
        const secondNativeAsset = assets.find((asset) => asset.id === pool.secondTokenId);

        if (firstNativeAsset && secondNativeAsset) {
          const poolMetadata: Pool = {
            ...pool,
            firstAsset: transformToAsset(firstNativeAsset),
            secondAsset: transformToAsset(secondNativeAsset),
            firstTokenRatio: Mangata.getPoolRatio(pool.firstTokenAmount, pool.secondTokenAmount),
            secondTokenRatio: Mangata.getPoolRatio(pool.secondTokenAmount, pool.firstTokenAmount),
            assetId: `${pool.firstTokenId}-${pool.secondTokenId}`,
            symbols: [firstNativeAsset.symbol, secondNativeAsset.symbol],
            isInverted: false,
          };

          return isFirstPoolTokenLeader(poolMetadata)
            ? getInvertedPool(poolMetadata)
            : poolMetadata;
        }

        return null;
      })
      .filter(Boolean) as Pool[];

    const invertedPools = basePools.map(getInvertedPool).filter(Boolean) as Pool[];
    const poolsData = [...basePools, ...invertedPools];
    const adjacentPools = poolsData.reduce((acc: Record<string, Pool[]>, pool) => {
      return {
        ...acc,
        [pool.firstTokenId]: [...(acc[pool.firstTokenId] || []), pool],
        [pool.secondTokenId]: [...(acc[pool.secondTokenId] || []), pool],
      };
    }, {});

    const poolsByAssetPair = poolsData.reduce((acc: Record<string, Pool[]>, pool) => {
      return {
        ...acc,
        [pool.assetId]: [...(acc[pool.assetId] || []), pool],
      };
    }, {});

    return {
      baseList: basePools,
      list: poolsData,
      byAssetPair: poolsByAssetPair,
      byAdjacentId: adjacentPools,
      byId: keyBy(poolsData, 'id'),
      byLPId: keyBy(basePools, 'liquidityTokenId'),
    };
  };
