import { useQuery } from '@tanstack/react-query';
import { fetchRollupChains } from '../services/rollupStashService';

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
};

export const useRollupChainsQuery = () => {
  const rollupChainsQuery = useQuery(['rollup-networks'], fetchRollupChains, {
    ...queryOptions,
  });

  return {
    rollupChainsQuery,
  };
};
