import { ReactComponent as WalletIcon } from 'icons/empty-wallet.svg';
import { TestId, WalletSource } from 'core';
import { Container, Text } from 'ui';
import { WalletCard } from '../WalletCard/WalletCard';
import { Connector, useConnect } from 'wagmi';

export const ALLOWED_WALLETS: string[] = [WalletSource.Metamask];

interface InstallWalletsProps extends TestId {
  onSelect: (wallet: string) => void;
}
export function InstalledWallets({
  onSelect,
  'data-testid': testId = 'installedWallets',
}: InstallWalletsProps) {
  const { connectors, connect } = useConnect();

  const filteredConnectors = connectors.filter(
    ({ name, type }) => ALLOWED_WALLETS.includes(name.toLowerCase()) || type === 'mock',
  );

  const handleSelect = (connector: Connector) => () => {
    connect({ connector });
    onSelect(connector.name);
  };

  const renderEmptyContent = () => (
    <Container
      alignItems="center"
      column
      fullWidth
      className="rounded-lg bg-default px-4 py-10"
      data-testid={testId}
    >
      <WalletIcon className="w-[40px] h-auto stroke-neutral" />
      <Container column fullWidth alignItems="center" className="text-center mt-4 px-4">
        <Text type="title-3" id="wallet.connect.empty.title" />
        <Text color="secondary" id="wallet.connect.empty.description" className="mt-2" />
      </Container>
    </Container>
  );

  const renderWallets = () => {
    return (
      <Container column fullWidth data-testid={testId}>
        {filteredConnectors.map((connector) => (
          <WalletCard
            key={connector.id}
            name={connector.name}
            onClick={handleSelect(connector)}
            data-testid={`${testId}-walletCard`}
          />
        ))}
      </Container>
    );
  };
  return filteredConnectors.length === 0 ? renderEmptyContent() : renderWallets();
}
