import { Button, Container, Text } from 'ui';
import { EnvConfig, useFaucetCatpchaMutation } from 'core';
import cls from 'classnames';
import { GASP_TOKEN, useFaucetContract, useMetamaskAccount } from '../hooks';
import { ReactComponent as CheckIcon } from 'icons/check-fill.svg';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';
import { Header } from '../layouts/Header';
import { useAccount } from 'wagmi';
import { AddTokenLayout } from '../layouts/AddTokenLayout';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useRef, useState } from 'react';
import { get } from 'lodash-es';
import { animated, useResize } from '@react-spring/web';

export function MainPage() {
  const { captchaMutation } = useFaucetCatpchaMutation();
  const { address: selectedAddress } = useAccount();
  const containerRef = useRef<HTMLDivElement>(null);

  const { addGaspMutation } = useFaucetContract(undefined, selectedAddress);
  const { connectWallet, error: walletError } = useMetamaskAccount();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const { height } = useResize({
    container: containerRef,
  });

  const error = walletError || get(captchaMutation.error?.response?.data, 'message');
  const isClaimable = selectedAddress && !!captchaToken;
  const isSuccess = captchaMutation.isSuccess;

  const handleHcaptchaVerify = (token: string) => {
    setCaptchaToken(token);
  };

  const handleVerification = () => {
    if (!selectedAddress || !captchaToken) {
      return;
    }

    captchaMutation.mutate({ token: captchaToken, address: selectedAddress });
  };

  const handleDisconnect = () => {
    setCaptchaToken(null);
    captchaMutation.reset();
  };

  return (
    <div
      className="prose relative h-[100vh] overflow-y-auto justify-center items-center flex flex-col"
      data-theme="day"
    >
      <Header onConnect={connectWallet} onDisconnect={handleDisconnect} />
      <Container className="h-full items-center justify-center mb-10 min-h-[500px]" column>
        <animated.div style={{ height, overflow: 'hidden' }}>
          <Container className="bg-default w-[480px] rounded-lg" ref={containerRef} column>
            <Container column className="p-8 ">
              <Container column className="mr-10">
                <Text type="display" className="text-[34px] leading-[1.2]">
                  Welcome&nbsp;to&nbsp;Gasp Testnet Faucet
                </Text>
                {!isSuccess && (
                  <Text type="title-2" className="mt-4 mb-2">
                    Connect your Metamask
                  </Text>
                )}
              </Container>
              {!isSuccess && (
                <Text type="body-l" color="secondary">
                  Connect your Ethereum wallet to claim GASPV2 testnet tokens
                </Text>
              )}
              {!isSuccess &&
                (selectedAddress ? (
                  <Container className="mt-4 px-4 bg-positive-soft rounded-lg h-[48px] w-full justify-center items-center">
                    <Container alignItems="center">
                      <CheckIcon className="w-6 h-6 mr-2 fill-positive" />
                      <Text type="body-m" color="positive">
                        Connected to Ethereum
                      </Text>
                    </Container>
                  </Container>
                ) : (
                  <Button onClick={connectWallet} className="mt-5">
                    Connect wallet
                  </Button>
                ))}

              {selectedAddress && (
                <Container fullWidth column className="mt-6">
                  {!isSuccess && EnvConfig.CAPTCHA_SITEKEY && (
                    <HCaptcha sitekey={EnvConfig.CAPTCHA_SITEKEY} onVerify={handleHcaptchaVerify} />
                  )}
                  {error && (
                    <Text
                      type="body-m"
                      className="inline-flex mt-4 items-center max-w-[400px]"
                      color="alert"
                    >
                      <WarningIcon className="w-6 h-6 mr-2 fill-alert min-w-[24px] self-start" />
                      {error}
                    </Text>
                  )}
                  <Button
                    size="m"
                    className={cls(
                      'mr-2 px-10 py-2',
                      !isSuccess && 'mt-6',
                      (captchaMutation.isLoading || isSuccess) &&
                        '!bg-base-accent/[0.1] text-accent font-data-m border-1 border-accent pointer-events-none',
                      'normal-case',
                    )}
                    isDisabled={!isClaimable || captchaMutation.isLoading}
                    onClick={handleVerification}
                    fullWidth
                    variant={isSuccess && isClaimable ? 'base' : 'primary'}
                  >
                    {isSuccess && <CheckIcon className="w-6 h-6 mr-2 fill-accent " />}
                    {captchaMutation.isLoading
                      ? 'Claiming...'
                      : isSuccess
                      ? `${GASP_TOKEN.name} Claimed`
                      : `Claim Testnet tokens`}
                  </Button>
                  {isSuccess && <AddTokenLayout addGaspMutation={addGaspMutation} />}
                </Container>
              )}
            </Container>
          </Container>
        </animated.div>

        {isSuccess && (
          <Container className="bg-accent rounded-lg px-5  w-[480px] mt-2 pb-5 pt-1" column>
            <Text type="body-l" className="mt-4" color="inverted">
              Don’t forget to deposit tokens to Gasp!
            </Text>
            <Text type="body-m" color="inverted" className="opacity-70 mt-2">
              After receiving your GASPV2 tokens, you need to deposit them to your Gasp account to
              be able to use them. Use the deposit feature directly in the Gasp app.
            </Text>
          </Container>
        )}
      </Container>
    </div>
  );
}
