import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Asset, PoolType } from 'core';
import { ProvideLiquidityFormError } from '../ProvideLiquidity';

export interface AddLiquidityUIStore {
  firstAmount: string;
  secondAmount: string;
  firstAsset: Asset | null;
  secondAsset: Asset | null;
  poolType: PoolType;
  formError: Partial<Record<ProvideLiquidityFormError, boolean>>;
  setPoolType: (poolType: PoolType) => void;
  setFirstAmount: (amount: string) => void;
  setSecondAmount: (amount: string) => void;
  setFirstAsset: (asset: Asset | null) => void;
  setSecondAsset: (asset: Asset | null) => void;
  setFormError: (value: Partial<Record<ProvideLiquidityFormError, boolean>>) => void;
  dispose: () => void;
}

const INITIAL_STATE = {
  firstAmount: '',
  secondAmount: '',
  firstAsset: null,
  secondAsset: null,
  routeDetails: null,
  poolType: PoolType.Xyk,
  formError: {},
};

export const useProvideLiquidityStore = create(
  devtools<AddLiquidityUIStore>((set) => ({
    ...INITIAL_STATE,
    setPoolType: (poolType) => set({ poolType }),
    setFirstAmount: (firstAmount) => set({ firstAmount }),
    setSecondAmount: (secondAmount) => set({ secondAmount }),
    setFirstAsset: (firstAsset) => set({ firstAsset }),
    setSecondAsset: (secondAsset) => set({ secondAsset }),
    setFormError: (formError) => set({ formError }),
    dispose: () => set(INITIAL_STATE),
  })),
);
