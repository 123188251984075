import { fromBN } from '@mangata-finance/sdk';
import { useInternalBalance, useMangataAsset } from 'core';
import { Container, FormatAmount, Text, TokenIcon } from 'ui';
import { ReactComponent as LockIcon } from 'icons/round-lock.svg';

export const NativeTokenWidgetBalance = () => {
  const { mgxBalance } = useInternalBalance();
  const { mangataAsset } = useMangataAsset();

  const free = mgxBalance && mangataAsset ? fromBN(mgxBalance.free, mangataAsset.decimals) : '0';
  const locked =
    mgxBalance && mangataAsset ? fromBN(mgxBalance.reserved, mangataAsset.decimals) : '0';

  return (
    <Container
      column
      fullWidth
      className="bg-default px-5 py-4 rounded-t-xl border-b border-default"
    >
      <Container alignItems="center">
        {mangataAsset && <TokenIcon size="l" token={mangataAsset} className="mr-3" />}
        <Text type="title-1">
          <FormatAmount
            value={free}
            options={{ maxChars: 10, precision: 0, nonZeroPrecision: false }}
          />
          <Text color="secondary" type="title-1">
            {' '}
            {mangataAsset?.symbol}
          </Text>
        </Text>
      </Container>

      <Text color="secondary" type="title-4" className="mt-1"></Text>

      <Container fullWidth alignItems="center" justifyContent="space-between" className="mt-3">
        <Text color="secondary">Temporarily Locked</Text>

        <Container alignItems="center">
          <LockIcon className="mr-1 w-4 h-auto fill-secondary" />
          <Text>
            <FormatAmount
              value={locked}
              options={{ maxChars: 10, precision: 0, nonZeroPrecision: false }}
            />{' '}
            {mangataAsset?.symbol}
          </Text>
        </Container>
      </Container>
    </Container>
  );
};
