import { create } from 'zustand';
import { Asset } from 'core';

export enum TokenSelectUIState {
  Default,
  Settings,
  ApproveUnknownToken,
}

export interface TokenSelectStore {
  uiState: TokenSelectUIState;
  setUIState: (state: TokenSelectUIState) => void;
  unknownAsset: Asset | null;
  setUnknownAsset(asset: Asset): void;
}

export const useTokenSelectStore = create<TokenSelectStore>((set) => ({
  unknownAsset: null,
  uiState: TokenSelectUIState.Default,
  setUnknownAsset: (unknownAsset) => set({ unknownAsset }),
  setUIState: (state) => set({ uiState: state }),
}));
