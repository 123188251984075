import { ApiPromise } from '@polkadot/api';

export const getTradeableTokenIds = (api: ApiPromise | null) => async () => {
  if (!api) {
    return null;
  }

  const tokensRes = await api.rpc.market.get_tradeable_tokens();
  const lpTokenRes = await api.rpc.market.get_pools_for_trading();

  const tokenIds = tokensRes.map((token) => token.tokenId.toString());
  const lpTokenIds = lpTokenRes.map((id) => id.toString());

  return [...tokenIds, ...lpTokenIds];
};
