import * as React from 'react';
import cls from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ClassName, TestId } from '../../types';
import { ForwardedRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { MessageDescriptor, PrimitiveType, useIntl } from 'react-intl';
import { Children } from 'core';
import { isNil } from 'lodash-es';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'primary-outlined'
  | 'alert'
  | 'premium'
  | 'base';
export type ButtonSize = 'huge' | 'l' | 'm' | 's' | 'xs';

export interface ButtonWithMessage {
  message: MessageDescriptor;
  values?: Record<string, PrimitiveType>;
  children?: never;
}

export interface ButtonWithChildren extends Children {
  message?: never;
  values?: never;
}

interface ButtonIconProps {
  LeadIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  TrailIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  iconClassName?: string;
  iconFill?: boolean;
  iconStroke?: boolean;
}

interface BaseButtonProps
  extends ClassName,
    TestId,
    ButtonIconProps,
    Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick' | 'onMouseDown'> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  isDisabled?: boolean;
  fullWidth?: boolean;
  element?: 'button' | 'link';
  to?: string;
}

type ButtonContent = ButtonWithMessage | ButtonWithChildren;

export type ButtonProps = BaseButtonProps & ButtonContent;

export const SIZES: Record<ButtonSize, string> = {
  huge: 'h-[56px] sm:min-w-[376px] font-button-l px-6',
  l: 'h-[56px] min-w-[50px] font-button-m px-6',
  m: 'h-[40px] min-w-[105px] font-button-m px-6',
  s: 'h-[40px] px-4 font-button-s',
  xs: 'h-[20px] font-body-m px-[6px]',
};

export const BUTTON_STYLES: Record<ButtonVariant, Record<string, string[]>> = {
  primary: {
    default: [
      'bg-gradient-to-b from-base-accent/[1] hover:from-base-accent/[0.8] to-base-accent/[1] hover:to-base-accent/[1]',
      'outline outline-4 outline-transparent outline-offset-0 hover:outline-base-accent/40',
      'text-inverted',
    ],
    disabled: ['bg-button-dimmed text-secondary'],
  },
  secondary: {
    default: ['hover:bg-hover border border-default text-secondary'],
    disabled: ['text-dimmed border border-default'],
  },
  'primary-outlined': {
    default: ['border hover:bg-hover-highlighted border-solid border-accent text-accent'],
    disabled: ['bg-button-dimmed border-none text-dimmed'],
  },
  premium: {
    default: [
      'outline outline-4 outline-transparent outline-offset-0 hover:outline-button-premium/[.4]',
      'px-6 bg-button-premium text-dimmed',
    ],
    disabled: ['bg-button-dimmed text-dimmed'],
  },
  alert: {
    default: [
      'outline outline-4 outline-transparent outline-offset-0 hover:outline-button-alert/[.4]',
      'px-6 bg-button-alert text-inverted',
    ],
    disabled: ['bg-button-dimmed text-dimmed'],
  },
  base: {
    default: ['outline-none'],
    disabled: [''],
  },
};

const ICON_SIZE: Record<ButtonSize, string> = {
  huge: 'w-[24px]',
  l: 'w-[24px]',
  m: 'w-[20px]',
  s: 'w-[14px]',
  xs: 'w-[12px]',
};

export const Button = forwardRef(
  (
    {
      variant = 'primary',
      size = 'm',
      isDisabled,
      onClick,
      type = 'button',
      className,
      iconClassName,
      'data-testid': testId,
      LeadIcon,
      TrailIcon,
      fullWidth,
      onMouseDown,
      element,
      to,
      message,
      values,
      children,
      iconFill = false,
      iconStroke = true,
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const intl = useIntl();

    const ICON_STYLES: Record<ButtonVariant, Record<'fill' | 'stroke', string>> = {
      primary: {
        fill: cls(isDisabled ? 'fill-accent' : 'fill-inverted'),
        stroke: cls(isDisabled ? 'stroke-secondary' : 'stroke-inverted'),
      },
      secondary: {
        fill: cls(isDisabled ? 'fill-secondary' : 'fill-secondary'),
        stroke: cls(isDisabled ? 'stroke-secondary' : 'stroke-secondary'),
      },
      premium: {
        fill: cls(isDisabled ? 'fill-dimmed' : 'fill-neutral'),
        stroke: cls(isDisabled ? 'stroke-dimmed' : 'stroke-neutral'),
      },
      'primary-outlined': {
        fill: cls(isDisabled ? 'fill-secondary' : 'fill-accent'),
        stroke: cls(isDisabled ? 'stroke-secondary' : 'stroke-accent'),
      },
      alert: {
        fill: cls(isDisabled ? 'fill-secondary' : 'fill-alert'),
        stroke: cls(isDisabled ? 'stroke-secondary' : 'stroke-alert'),
      },
      base: {
        fill: '',
        stroke: '',
      },
    };

    const classNames = twMerge(
      cls(
        'box-border flex items-center justify-center rounded-full focus:outline-none min-w-max whitespace-nowrap transition-all',
        !isDisabled && [...BUTTON_STYLES[variant].default],
        variant !== 'base' && SIZES[size],
        isDisabled && `cursor-not-allowed hover:!outline-0 ${BUTTON_STYLES[variant].disabled}`,
        variant === 'secondary' && (LeadIcon || TrailIcon) && 'px-4',
        variant === 'primary' && size === 'huge' && isDisabled && 'bg-accent-soft text-accent',
        size !== 'huge' && size !== 'l' && '!outline-none',
        fullWidth && 'w-full',
        className,
      ),
    );

    const Body = (
      <>
        {LeadIcon && (
          <div className="mr-2">
            <LeadIcon
              className={cls(
                'h-auto',
                iconFill && ICON_STYLES[variant].fill,
                iconStroke && ICON_STYLES[variant].stroke,
                ICON_SIZE[size],
                iconClassName,
              )}
            />
          </div>
        )}
        {!isNil(message) ? intl.formatMessage(message, values) : children}
        {TrailIcon && (
          <div className="ml-2">
            <TrailIcon
              className={cls(
                'h-auto',
                iconFill && ICON_STYLES[variant].fill,
                iconStroke && ICON_STYLES[variant].stroke,
                ICON_SIZE[size],
                iconClassName,
              )}
            />
          </div>
        )}
      </>
    );

    if (element === 'link' && to) {
      return (
        <Link to={to} className={classNames} data-testid={testId}>
          {Body}
        </Link>
      );
    }

    return (
      <button
        ref={ref}
        onMouseDown={onMouseDown}
        className={classNames}
        onClick={onClick}
        disabled={isDisabled}
        type={type}
        data-testid={testId}
      >
        {Body}
      </button>
    );
  },
);
