import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum WalletStatus {
  SelectWallet = 'selectWallet',
  SelectAccount = 'selectAccount',
  Connected = 'connected',
}

export interface WalletUIStore {
  activeTab: number;
  status: WalletStatus;
  setStatus: (status: WalletStatus) => void;
  setActiveTab: (tab: number) => void;
}

export const useWalletUIStore = create(
  devtools<WalletUIStore>((set) => ({
    activeTab: 0,
    status: WalletStatus.SelectWallet,
    setStatus: (status) => set({ status }),
    setActiveTab: (activeTab) => set({ activeTab }),
  })),
);
