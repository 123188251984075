import { ClassName, TestId } from 'core';
import { Decimal } from 'decimal.js';
import cls from 'classnames';
import { ReactComponent as TrendIcon } from '../../../assets/icons/trend.svg';
import { Container } from '../Container/Container';
import { Text } from '../Text/Text';
import { FormatAmount } from '../Format';

interface TrendProps extends ClassName, TestId {
  value: Decimal;
  suffix?: string;
  withBackground?: boolean;
}
export function Trend({
  value,
  suffix = '%',
  className,
  'data-testid': testId,
  withBackground,
}: TrendProps) {
  const isNegative = value.isNegative();

  return (
    <Container
      inline
      alignItems="center"
      className={cls(
        'whitespace-nowrap',
        className,
        withBackground && `px-1 rounded-sm ${isNegative ? 'bg-alert-soft' : 'bg-positive-soft'}`,
      )}
    >
      <TrendIcon
        className={cls(
          'w-[16px] h-auto mr-2',
          isNegative ? 'stroke-alert' : 'stroke-positive',
          isNegative && 'transform -scale-y-100',
        )}
      />
      <Text color={isNegative ? 'alert' : 'positive'}>
        <FormatAmount
          data-testid={testId}
          value={value.toString()}
          options={{ precision: 1, nonZeroPrecision: true, minThreshold: '0.01' }}
        />
        {`${suffix}`}
      </Text>
    </Container>
  );
}
