import cls from 'classnames';
import Decimal from 'decimal.js';
import { first, last } from 'lodash-es';
import { forwardRef, useState } from 'react';
import {
  ChartDropdown,
  Container,
  LineChart,
  Timeframe,
  TimeframeSelector,
  timeframes,
  Text,
  Trend,
  formatAmount,
} from 'ui';
import { useTokenPriceHistoryQuery, ChartHistoryInterval, ClassName, getAssetTitle } from 'core';
import { useSwapStore } from '../store/useSwapStore';

const getDeltaPerc = (firstAmount: string | null, secondAmount: string | null) => {
  if (!firstAmount || !secondAmount) {
    return null;
  }

  const firstDec = new Decimal(firstAmount);
  const secondDec = new Decimal(secondAmount);

  const delta = secondDec.minus(firstDec);
  const average = firstDec.plus(secondDec).dividedBy(2);

  return delta.dividedBy(average).times(100);
};

type SwapChartProps = ClassName;

export const SwapChart = forwardRef<HTMLDivElement, SwapChartProps>(({ className }, ref) => {
  const { firstToken, secondToken } = useSwapStore();
  const [currentTimeframe, setTimeframe] = useState<Timeframe>(timeframes[1]);
  const [activePairIndex, setActivePairIndex] = useState(0);

  const pairOptions = [
    [firstToken, secondToken],
    [secondToken, firstToken],
  ];
  const activePair = pairOptions[activePairIndex] || pairOptions[0];
  const areTokensSelected = firstToken && secondToken;

  const { pairPriceHistoryQuery } = useTokenPriceHistoryQuery(
    activePair[0]?.id,
    activePair[1]?.id,
    {
      days: currentTimeframe.days,
      interval: currentTimeframe.interval,
    },
  );

  const { pairPriceHistoryQuery: latestPriceQuery } = useTokenPriceHistoryQuery(
    activePair[0]?.id,
    activePair[1]?.id,
    {
      days: 1,
      interval: ChartHistoryInterval.Day,
    },
  );

  const latestPrice =
    last(latestPriceQuery.data?.prices)?.[1] ||
    last(pairPriceHistoryQuery.data?.prices)?.[1] ||
    null;
  const firstHistoryPrice = first(pairPriceHistoryQuery.data?.prices)?.[1] || null;
  const deltaPerc = getDeltaPerc(firstHistoryPrice, latestPrice);

  const data = pairPriceHistoryQuery.data?.prices.map(([timestamp, volume]) => ({
    x: new Date(timestamp),
    y: volume,
  }));

  const onPairSelect = (i: number) => setActivePairIndex(i);

  const formatYAxis = (value: string) =>
    formatAmount(value, { nonZeroPrecision: true, precision: 2 }) || '';
  const formatTooltipYValue = (value: string) =>
    `${formatAmount(value, { nonZeroPrecision: true, precision: 2 }) || '0'} ${getAssetTitle(
      activePair[1],
    )}`;

  return (
    <Container
      className={cls('mt-10 w-full h-[435px] overflow-visible', className)}
      column
      ref={ref}
    >
      {areTokensSelected && (
        <Container column fullWidth>
          <Container
            fullWidth
            justifyContent="space-between"
            className="flex-col xs:flex-row items-start xs:items-center "
          >
            <ChartDropdown
              onSelect={onPairSelect}
              data={pairOptions.map(([firstAsset, secondAsset]) =>
                [getAssetTitle(firstAsset), getAssetTitle(secondAsset)].join(' / '),
              )}
              titleId="swap.pair.dropdown.title"
              activeIndex={activePairIndex > (pairOptions?.length || 0) ? 0 : activePairIndex}
              className="mb-4 xs:mb-0"
            />
            <TimeframeSelector onSelect={setTimeframe} currentTimeframe={currentTimeframe} />
          </Container>
          {latestPrice && (
            <Container className="mt-6 ml-1 xs:ml-4 flex-col xs:flex-row items-start xs:items-center">
              <Container alignItems="center" className="mb-3 xs:mb-0">
                <Text type="title-2" color="secondary" className="mr-1 whitespace-nowrap">
                  1 {activePair[0]?.symbol} =
                </Text>
                <Text type="title-2" className="mr-2 text-[15px] xs:font-title-2">
                  {formatAmount(latestPrice, {
                    precision: 3,
                    nonZeroPrecision: true,
                    maxChars: 14,
                    minThreshold: '0.001',
                  })}
                  &nbsp;
                  {getAssetTitle(activePair[1])}
                </Text>
              </Container>
              {deltaPerc && <Trend value={deltaPerc} withBackground />}
            </Container>
          )}
        </Container>
      )}
      <LineChart
        className="pt-6"
        data={data}
        timeframe={currentTimeframe}
        id={`swap-${currentTimeframe.key}`}
        formatYAxis={formatYAxis}
        formatTooltipYValue={formatTooltipYValue}
        isEmpty={
          !areTokensSelected || !pairPriceHistoryQuery.isLoading || pairPriceHistoryQuery.isError
        }
      />
    </Container>
  );
});
