import { flatten, isNil } from 'lodash-es';
import { QueryOptional, Web3Contexts, AssetRegistryAssetMetadata } from '../../../../../services';
import { AssetOrigin } from '../../../../token';
import { erc20Abi } from '../../../ContractAbis.json';
import { RollupStashChain, RollupToken } from '../../../stash/RollupStash';
import { RollupTokenAddressMaps } from '../../address/transformer/rollupTokenAddressesTransformer';
import { CoingeckoContractInfoQueries } from '../../../../coingecko';
import { MangataTypesAssetsL1Asset } from '@polkadot/types/lookup';
import { EnvConfig } from '../../../../../envConfig';

export const NATIVE_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000001';

export const fetchRollupTokens =
  (
    web3Contexts: QueryOptional<Web3Contexts>,
    addressMaps: QueryOptional<RollupTokenAddressMaps | null>,
    chains: QueryOptional<RollupStashChain[]>,
    assetMetadata: QueryOptional<AssetRegistryAssetMetadata[]>,
    contractsInfo: CoingeckoContractInfoQueries,
  ) =>
  async (): Promise<RollupToken[]> => {
    if (!addressMaps || !web3Contexts || !chains || !assetMetadata) {
      return [];
    }

    const allChainKeys = chains.map((chain) => chain.key);
    const chainKeys = addressMaps.l1ToL2 ? Array.from(addressMaps.l1ToL2.entries()) : [];

    const entries = allChainKeys.reduce<[MangataTypesAssetsL1Asset['type'], string[]][]>(
      (acc, chainKey) => {
        const entry = chainKeys.find(([key]) => key === chainKey);
        if (entry) {
          const [, l1AddressesMap] = entry;
          acc.push([chainKey, Array.from(l1AddressesMap.keys())]);
        } else {
          acc.push([chainKey, [NATIVE_TOKEN_ADDRESS]]);
        }
        return acc;
      },
      [],
    );

    const tokenPromises = flatten(
      entries.map(([chainKey, l1Addresses]) => {
        return l1Addresses.map(async (address) => {
          try {
            const chainId = chains.find((chain) => chain.key === chainKey)?.chainId;
            if (!chainId) {
              return null;
            }

            const contract = new web3Contexts[chainId].eth.Contract(erc20Abi, address);
            const chainNativeToken = chains.find((chain) => chain.key === chainKey)?.nativeToken;
            const l2Id = addressMaps.l1ToL2.get(chainKey)?.get(address) ?? null;
            const chainOrigin = {
              Ethereum: AssetOrigin.Ethereum,
              Arbitrum: AssetOrigin.Arbitrum,
              Base: AssetOrigin.Base,
            }[chainKey];

            const coingeckoContractData = contractsInfo.get(address)?.data?.data.attributes;

            if (address === NATIVE_TOKEN_ADDRESS && chainNativeToken) {
              const { symbol, name, decimals } = chainNativeToken;

              return {
                contract: null,
                name,
                l2Id,
                symbol,
                decimals,
                origin: chainOrigin,
                isNative: true,
                source: {
                  address,
                  chainId: chainId,
                  iconUrl: coingeckoContractData?.image_url,
                },
              };
            }

            const symbol = (await contract?.methods.symbol().call())?.toString();
            const name = (await contract?.methods.name().call())?.toString();
            const decimals = (await contract?.methods.decimals().call())?.toString();

            const isNativeOrigin =
              l2Id === EnvConfig.TOKEN_ID ||
              assetMetadata.find(
                (asset) => asset.id === l2Id && asset.symbol === symbol && asset.name === name,
              );

            if (!name || !symbol || !decimals || !address || !contract) {
              return null;
            }

            return {
              contract,
              name,
              l2Id,
              symbol,
              decimals,
              origin: isNativeOrigin ? AssetOrigin.Native : chainOrigin,
              isNative: false,
              source: {
                address,
                chainId: chainId,
                iconUrl: coingeckoContractData?.image_url,
              },
            };
          } catch (e) {
            return null;
          }
        });
      }),
    );

    const data = (await Promise.all(tokenPromises)).filter(($): $ is RollupToken => !isNil($));
    return data;
  };
