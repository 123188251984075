import { Asset } from '../../';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface CustomAssetsUIStore {
  assets: Asset[];
  addAsset: (asset: Asset) => void;
  dispose: () => void;
}

export const useCustomAssetsStore = create(
  persist<CustomAssetsUIStore>(
    (set) => ({
      assets: [],
      addAsset: (asset) => set((state) => ({ assets: [...state.assets, asset] })),
      dispose: () => set(() => ({ assets: [] })),
    }),
    {
      name: 'custom-assets',
      storage: createJSONStorage(() => localStorage),
      version: 1,
    },
  ),
);
