import { NATIVE_TOKEN_ADDRESS, RollupStashChain, useRollupGasPriceQuery } from '../../rollup';
import { Asset, useTokenPrice } from '../../token';
import { useCoingeckoTokenPriceQuery } from '../../coingecko';
import { Decimal } from 'decimal.js';

const EIGEN_PROCCESING_TIME = 120; // minutes
const APR = 1;

const CLOSING_TX_GAS = new Decimal('124780').div('1e18');
const FERRY_TX_GAS = new Decimal('91539').div('1e18');

export const useWithdrawalFerryFee = (
  chain: RollupStashChain | undefined,
  asset: Asset | null,
  amount: string | null,
) => {
  const {
    rollupGasPriceQuery: { data: _gasPrice },
  } = useRollupGasPriceQuery(chain?.chainId);
  const {
    coingeckoTokenPriceQuery: { data: tokenPrice, isLoading: isCoingeckoTokenPriceLoading },
  } = useCoingeckoTokenPriceQuery(chain?.key, asset?.source?.address);

  const {
    coingeckoTokenPriceQuery: { data: _ethPrice, isLoading: isEthPriceLoading },
  } = useCoingeckoTokenPriceQuery('Ethereum', NATIVE_TOKEN_ADDRESS);

  const { price: stashTokenPrice, isLoading: isStashTokenPriceLoading } = useTokenPrice(asset?.id);

  const isLoading = isCoingeckoTokenPriceLoading || isEthPriceLoading || isStashTokenPriceLoading;

  if (!chain || !asset || !_gasPrice || !amount || isLoading) {
    return null;
  }

  const withdrawalAssetPrice = new Decimal(tokenPrice?.usd ?? stashTokenPrice ?? '0');
  const gasPrice = new Decimal(_gasPrice);
  const ethPrice = new Decimal(_ethPrice?.usd ?? '0');

  if (withdrawalAssetPrice.eq(0)) {
    return new Decimal(amount).mul(`1e${asset.decimals}`).mul(0.01);
  }

  const txClosingAmount = CLOSING_TX_GAS.mul(gasPrice)
    .mul(ethPrice)
    .div(withdrawalAssetPrice)
    .toFixed();
  const txFerryAmount = FERRY_TX_GAS.mul(gasPrice)
    .mul(ethPrice)
    .div(withdrawalAssetPrice)
    .toFixed();

  const interest = new Decimal(amount).mul(APR).mul(EIGEN_PROCCESING_TIME).div(60).div(24).div(365);

  const ferryTip = interest
    .add(txClosingAmount)
    .add(txFerryAmount)
    .mul(`1e${asset.decimals}`)
    .toFixed(0);

  return new Decimal(ferryTip);
};
