import { isString } from 'lodash-es';
import { QueryClient, QueryCache } from '@tanstack/react-query';
import { PersistQueryClientProviderProps } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { globalQueryErrorHandler } from '../../domains/error/queryError';
import { EnvConfig } from '../../envConfig';
import { millisecondsInMinute, millisecondsInHour } from 'date-fns';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: globalQueryErrorHandler,
  }),
});

export type QueryOptional<T> = T | undefined | null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryServiceReturnType<T extends (...args: any) => any> = Awaited<
  ReturnType<ReturnType<T>>
>;

export const DEFAULT_QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: millisecondsInMinute * 5,
};

export const QUERY_REFETCH_INTERVAL = {
  externalBalance: 5000,
  internalBalance: 5000,
  internalBalanceDepositsPending: 1000,
  transactionTracing: 5000,
};

const QUERIES_TO_PERSIST = ['token-price', 'coingecko-token-list', 'coingecko-contract-info'];

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export const queryPersistOptions: PersistQueryClientProviderProps['persistOptions'] = {
  persister,
  buster: EnvConfig.APP_VERSION,
  maxAge: millisecondsInHour * 24,
  dehydrateOptions: {
    shouldDehydrateQuery: (query) =>
      query.queryKey.some((key) => isString(key) && QUERIES_TO_PERSIST.includes(key as string)),
  },
};
