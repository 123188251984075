import { Link } from 'react-router-dom';
import cls from 'classnames';
import { Container, Text } from 'ui';
import { ReactComponent as LowLiquidityIcon } from 'assets/icons/lowLiquidity.svg';
import { ReactComponent as NoLiquidityIcon } from 'assets/icons/noLiquidity.svg';
import { ClassName, RouteDataError, TestId } from 'core';

interface SwapBannerProps extends ClassName, TestId {
  variant: RouteDataError;
}

const VARIANT_STYLES = {
  [RouteDataError.InsufficientLiquidity]: {
    Icon: <LowLiquidityIcon className={cls('w-8 h-8 mr-6')} />,
  },
  [RouteDataError.NoPools]: {
    Icon: <NoLiquidityIcon className={cls('w-8 h-8 mr-6')} />,
  },
  [RouteDataError.InsufficientInputAmount]: null,
};

export const SwapBanner = ({
  variant,
  className,
  'data-testid': testId = 'swapBanner',
}: SwapBannerProps) => {
  if (!VARIANT_STYLES[variant]) return null;

  const AddLiquidityCta = (
    <Link to="/pools">
      <Text
        id="swap.banner.cta.addLiquidity"
        className="underline"
        weight="black"
        color="attention"
      />
    </Link>
  );

  return (
    <Container
      alignItems="center"
      data-testid={testId}
      className={cls('rounded-xl w-full p-4 bg-attention-soft', className)}
    >
      <div className="self-start">
        <NoLiquidityIcon className={cls('w-6 h-auto fill-attention stroke-attention')} />
      </div>
      <Container className="ml-3">
        <Text
          color="attention"
          id={`swap.banner.${RouteDataError.InsufficientLiquidity}`}
          values={{ addLiquidity: AddLiquidityCta }}
        />
      </Container>
    </Container>
  );
};
