import { animated, useTransition } from '@react-spring/web';
import { useAccounts } from 'core';
import { isNull } from 'lodash-es';
import { forwardRef } from 'react';
import { Container } from 'ui';
import { NativeTokenWidgetBalance } from './NativeTokenWidgetBalance';
import { NativeTokenWidgetOnboarding } from './NativeTokenWidgetOnboarding';
import cls from 'classnames';
import { EnvConfig } from 'core';
import { NativeTokenWidgetGetGasp } from './NativeTokenWidgetGetGasp';
import { NativeTokenWidgetStaking } from './NativeTokenWidgetStaking';

export const NativeTokenWidgetContent = forwardRef<HTMLDivElement>((_, ref) => {
  const { selectedAccount } = useAccounts();

  const content: Array<[string, JSX.Element | null]> = [
    ['0', selectedAccount?.address ? <NativeTokenWidgetBalance /> : null],
    ['1', EnvConfig.isMainnetEnv ? <NativeTokenWidgetGetGasp /> : <NativeTokenWidgetOnboarding />],
    ['2', EnvConfig.isMainnetEnv ? <NativeTokenWidgetStaking /> : null],
  ].filter((item): item is [string, JSX.Element] => !isNull(item[1]));

  const contentTransition = useTransition(content, {
    from: { opacity: 0, y: 50 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -50 },
    keys: content.map((item) => item[0]),
    trail: 100,
  });

  return (
    <Container
      ref={ref}
      column
      className={cls(
        'absolute right-0 border bg-default border-strong rounded-xl',
        'py-2 w-[340px] gap-y-2 z-dropdown -mt-4 shadow-xl',
      )}
    >
      {contentTransition((style, item) => (
        <animated.div style={style} className="w-full">
          {item[1]}
        </animated.div>
      ))}
    </Container>
  );
});
