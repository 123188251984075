import { Decimal } from 'decimal.js';
import { Contract, Web3 } from 'web3';
import { rolldownAbi } from '../../../ContractAbis.json';
import { QueryFunctionContext, UseMutateFunction } from '@tanstack/react-query';
import { Asset, QueryOptional, TransactionStore, TxType, transformToAsset } from '../../../../../';
import { RollupChainTx } from '../../../../transaction';
import { RollupToken, RollupStashChain } from '../../../stash/RollupStash';
import { NATIVE_TOKEN_ADDRESS } from '../../../token/list/services/rollupTokensService';
import { StartTxTrackingProps } from '../../../stash/tracking/start/services/startTrackingService';
import { Config } from 'wagmi';
import { Hex } from 'viem';

export type RollupDepositQueryParams = [
  string,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<boolean>,
];

export interface RollupDepositMutationParams {
  userAddress: QueryOptional<string>;
  token: RollupToken;
  amount: string;
  gasAmount: QueryOptional<string>;
  chain: QueryOptional<RollupStashChain>;
  ferryTip: QueryOptional<string>;
}

const web3 = new Web3();

export const getRollupDepositFee =
  (rolldownContract: QueryOptional<Contract<typeof rolldownAbi>>, asset: QueryOptional<Asset>) =>
  async ({ queryKey }: QueryFunctionContext<RollupDepositQueryParams>) => {
    const [, tokenAddress, userAddress, amount, gasPrice, ferryTip] = queryKey;

    if (!tokenAddress || !amount || !userAddress || !gasPrice || !rolldownContract || !asset) {
      return null;
    }

    const isNativeToken = tokenAddress === NATIVE_TOKEN_ADDRESS;
    const amountDec = new Decimal(`${amount}e${asset.decimals}`);

    const depositMethod = (() => {
      if (isNativeToken) {
        return rolldownContract.methods.deposit_native(ferryTip);
      } else {
        return rolldownContract.methods.deposit_erc20(tokenAddress, amountDec.toFixed(), ferryTip);
      }
    })();

    const gasAmount =
      (
        await depositMethod.estimateGas({
          from: userAddress,
          value: isNativeToken ? new Decimal(amountDec).toHex() : undefined,
        })
      ).toString() || '0';
    const totalInWei = new Decimal(gasAmount).mul(gasPrice).toFixed();

    return {
      gasAmount,
      ethAmount: web3.utils.fromWei(totalInWei, 'ether'),
    };
  };

export const submitRollupDeposit =
  (
    transactionStore: TransactionStore,
    config: QueryOptional<Config>,
    rolldownContract: QueryOptional<Contract<typeof rolldownAbi>>,
    gasPrice: QueryOptional<string>,
    startTracking: UseMutateFunction<null, unknown, StartTxTrackingProps, unknown>,
  ) =>
  async ({
    token,
    userAddress,
    amount,
    gasAmount,
    chain,
    ferryTip,
  }: RollupDepositMutationParams) => {
    if (!config || !gasPrice || !userAddress || !rolldownContract || !chain || !gasAmount) {
      return;
    }

    const amountDec = new Decimal(amount).mul(`1e${token.decimals}`);
    const depositMethod = (() => {
      if (token.isNative) {
        return rolldownContract.methods.deposit_native(ferryTip?.toString() ?? '0');
      } else {
        return rolldownContract.methods.deposit_erc20(
          token.source.address,
          amountDec.toFixed(),
          ferryTip?.toString() ?? '0',
        );
      }
    })();

    if (!rolldownContract.options.address) {
      return;
    }

    const txParams = {
      account: userAddress as Hex,
      to: rolldownContract.options.address as Hex,
      data: depositMethod.encodeABI() as Hex,
      gasPrice: BigInt(gasPrice),
      gas: BigInt(gasAmount),
      value: token.isNative && amountDec ? BigInt(new Decimal(amountDec).toFixed()) : undefined,
    };

    const tx = new RollupChainTx(config, transactionStore)
      .create(TxType.RollupDeposit)
      .setMetadata({ tokens: [{ ...transformToAsset(token), amount }] })
      .setOptions({ showExplorerLink: false, doneOnTrigger: false, isVisible: false })
      .setTx(txParams, chain.explorerUrl)
      .build();

    const txHash = await tx.send();

    if (txHash) {
      startTracking({
        txHash,
        userAddress,
        amount: amountDec.toFixed(),
        assetAddress: token.source.address,
        chain,
        type: 'deposit',
      });

      window.safary?.track({
        eventType: 'deposit',
        eventName: 'submit-deposit',
        parameters: {
          walletAddress: userAddress,
          amount: amount,
          currency: token.symbol,
          currencyOrigin: token?.source?.chainId || '',
          contractAddress: token.source.address,
          transactionHash: txHash,
        },
      });
    }

    return !!txHash;
  };
