import cls from 'classnames';

import { ClassName, TestId } from '../../types';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/check-fill.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/alert.svg';
import { ReactComponent as SpinnerIcon } from '../../../assets/icons/spinner.svg';

export type SpinnerState = 'loading' | 'success' | 'error';

export interface SpinnerProps extends ClassName, TestId {
  state?: SpinnerState;
}

export function Spinner({ className, 'data-testid': testId, state }: SpinnerProps) {
  switch (state) {
    case 'success':
      return (
        <div className="bg-positive-soft rounded-full p-2">
          <SuccessIcon
            data-testid={`${testId}-success`}
            className={cls('h-[auto] w-[18px] fill-positive', className)}
          />
        </div>
      );
    case 'error':
      return (
        <div className="bg-alert-soft rounded-full p-2">
          <ErrorIcon
            data-testid={`${testId}-error`}
            className={cls('h-[auto] w-[18px] fill-alert', className)}
          />
        </div>
      );

    default:
      return (
        <SpinnerIcon
          data-testid={`${testId}-loading`}
          className={cls('h-[auto] w-[25px] animate-spin stroke-accent', className)}
        />
      );
  }
}
