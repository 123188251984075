import { ApiPromise } from '@polkadot/api';

export const getAllPools = (api: ApiPromise | null) => async () => {
  if (!api) {
    return null;
  }

  const res = await api.rpc.market.get_pools(null);
  return res;
};
