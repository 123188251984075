import { QueryFunctionContext } from '@tanstack/react-query';
import {
  Asset,
  ExtrinsicTx,
  queryClient,
  QueryOptional,
  RollupStashChain,
  TransactionStore,
  TxType,
} from '../../../../..';
import { ApiPromise } from '@polkadot/api';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN, toBN } from 'gasp-sdk';
import { MangataTypesAssetsL1Asset } from '@polkadot/types/lookup';
import { Config } from 'wagmi';
import {
  TxTrackingByAddressItem,
  TxTrackingStatus,
} from '../../../stash/tracking/list/services/txTrackingListService';
import Decimal from 'decimal.js';
import { get } from 'lodash-es';
import { EnvConfig } from '../../../../../envConfig';

export type RollupWithdrawalQueryParams = [
  string,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<MangataTypesAssetsL1Asset['type']>,
  QueryOptional<string>,
];

export interface RollupWithdrawalMutationParams {
  userAddress: QueryOptional<string>;
  tokenAddress: QueryOptional<string>;
  destinationAddress: QueryOptional<string>;
  destinationChain: QueryOptional<RollupStashChain>;
  asset: QueryOptional<Asset>;
  amount: string;
  ferryTip: QueryOptional<string>;
}

const FLAT_WITHDRAWAL_FEE = toBN(
  EnvConfig.FLAT_WITHDRAWAL_FEE,
  BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
);

export const getRollupWithdrawalFee =
  (api: QueryOptional<ApiPromise>) =>
  async ({ queryKey }: QueryFunctionContext<RollupWithdrawalQueryParams>) => {
    const [, tokenAddress, userAddress, amount, chainKey, ferryTip] = queryKey;

    if (!tokenAddress || !amount || !userAddress || !api || !chainKey) {
      return null;
    }

    try {
      const extrinsic = api.tx.rolldown.withdraw(
        chainKey,
        userAddress,
        tokenAddress,
        toBN(amount).toString(),
        ferryTip ?? null,
      );

      const feeInfo = await extrinsic.paymentInfo(userAddress);

      return fromBN(
        feeInfo.partialFee.add(FLAT_WITHDRAWAL_FEE),
        BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
      );
    } catch (error) {
      throw new Error(`Fee fetching failed. ${error}`);
    }
  };

export const submitRollupWithdrawal =
  (
    api: QueryOptional<ApiPromise>,
    transactionStore: TransactionStore,
    config: QueryOptional<Config>,
  ) =>
  async ({
    asset,
    userAddress,
    amount,
    destinationAddress,
    tokenAddress,
    destinationChain,
    ferryTip,
  }: RollupWithdrawalMutationParams) => {
    if (
      !api ||
      !config ||
      !userAddress ||
      !amount ||
      !asset ||
      !destinationAddress ||
      !tokenAddress ||
      !destinationChain
    ) {
      return;
    }

    const extrinsic = api.tx.rolldown.withdraw(
      destinationChain.key,
      destinationAddress,
      tokenAddress,
      toBN(amount, asset.decimals).toString(),
      ferryTip ?? null,
    );

    const tx = new ExtrinsicTx(api, transactionStore, config, userAddress)
      .create(TxType.RollupWithdrawal)
      .setMetadata({ tokens: [{ ...asset, amount }] })
      .setTx(extrinsic)
      .setOptions({ isVisible: false })
      .build();

    const res = await tx.send();

    const withdrawalEvent = res?.find((event) => event.method === 'WithdrawalRequestCreated');

    if (asset.source && withdrawalEvent) {
      await queryClient.cancelQueries({ queryKey: ['tx-tracking-list'], exact: false });
      const txHash = get(withdrawalEvent.event.data, 'hash_', '').toString();

      const newTx: TxTrackingByAddressItem = {
        txHash,
        requestId: null,
        asset_address: asset.source?.address,
        amount: new Decimal(`${amount}e${asset.decimals}`).toFixed(),
        address: userAddress,
        created: Date.now(),
        updated: Date.now(),
        status: TxTrackingStatus.Initiated,
        type: 'withdrawal',
        asset_chainId: asset.source?.chainId,
      };

      queryClient.setQueryData<TxTrackingByAddressItem[]>(
        ['tx-tracking-list', userAddress],
        (data) => (data ? [...data, newTx] : data),
      );

      window.safary?.track({
        eventType: 'withdrawal',
        eventName: 'submit-withdrawal',
        parameters: {
          walletAddress: userAddress,
          amount: amount,
          currency: asset.symbol,
          currencyOrigin: asset?.source?.chainId || '',
          destinationChain: destinationChain.key,
          contractAddress: asset.source?.address,
          txHash,
        },
      });
    }

    return !!res;
  };
