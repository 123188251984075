import { WalletSource } from '../../../types';
import {
  getInjectedExtensions,
  InjectedWallet,
  InjectedWindowProvider,
  SentryTag,
} from '../../../services';
import * as Sentry from '@sentry/react';
import { KeypairTypeValue } from '../../account';

export const DISABLED_WALLET_SOURCES = WalletSource.Metamask;

export const fetchWallets = async () => {
  const extensions = getInjectedExtensions();

  extensions.forEach((_, key) => {
    if (DISABLED_WALLET_SOURCES.includes(key as WalletSource)) {
      extensions.delete(key);
    }
  });

  if (extensions.size === 0) {
    return null;
  }

  if (window.walletExtension?.isNovaWallet) {
    const novaWalletInstance = extensions.get(WalletSource.PolkadotJS);

    if (novaWalletInstance) {
      extensions.delete(WalletSource.PolkadotJS);
      extensions.set(WalletSource.NovaWallet, novaWalletInstance);
    }
  }

  return extensions;
};

export const getSelectedWallet =
  (
    selectedWallet: string | null,
    wallets: Map<string, InjectedWindowProvider> | null | undefined,
  ) =>
  async (): Promise<InjectedWallet | null> => {
    if (!selectedWallet || !wallets) return null;

    const injectedWallet = wallets.get(selectedWallet);
    const injectedPolkadotWallet = await injectedWallet?.enable?.(
      'Gasp Distribution Account Pairing',
    );
    const accounts = await injectedPolkadotWallet?.accounts.get();

    if (!injectedPolkadotWallet) {
      return null;
    }

    Sentry.setTag(SentryTag.WalletExtension, selectedWallet);

    return {
      ...injectedPolkadotWallet,
      accounts:
        accounts?.map((account) => ({
          address: account.address,
          name: account.name,
          type: (account.type as KeypairTypeValue) || null,
        })) || null,
    };
  };
