import {
  InjectedWindowProvider as InjectedPolkadotProvider,
  Injected,
} from '@polkadot/extension-inject/types';
import { KeypairTypeValue } from '../../domains';

let injected = window.injectedWeb3;

export interface WalletAccount {
  address: string;
  name?: string;
  type: KeypairTypeValue | null;
}

export interface InjectedWallet extends Partial<Pick<Injected, 'signer'>> {
  accounts: WalletAccount[] | null;
}

export type InjectedWindowProvider = InjectedPolkadotProvider | null;

Object.defineProperty(window, 'injectedWeb3', {
  configurable: true,
  enumerable: true,
  get() {
    return injected;
  },
  set(value) {
    injected = value;
  },
});

export function getInjectedExtensions() {
  if (!injected) {
    return new Map<string, InjectedWindowProvider>();
  }

  return new Map<string, InjectedWindowProvider>(Object.entries(injected));
}

export function getExtensionsCount() {
  if (!injected) {
    return null;
  }

  return Object.keys(injected).length;
}
