import { Button, Container, Text } from 'ui';

export const NativeTokenWidgetOnboarding = () => {
  return (
    <Container className="px-2">
      <Container fullWidth column className="bg-accent-soft rounded-xl px-5 py-6">
        <Text type="title-2" id="nativeTokenWidget.getTokens.title" />
        <Text color="secondary" id="nativeTokenWidget.getTokens.desc" className="mt-2" />
        <a
          href="https://holesky-faucet.gasp.xyz/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-full"
        >
          <Button
            variant="primary"
            size="m"
            fullWidth
            className="mt-6"
            message={{ id: 'nativeTokenWidget.getTokens.cta' }}
          />
        </a>
      </Container>
    </Container>
  );
};
