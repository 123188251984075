import { TestId } from '../../types';
import { TokenIconSize, TokenIconType } from './TokenIcon';
import { Asset, AssetType } from 'core';
import cls from 'classnames';
import { uniqueId } from 'lodash-es';
import { ReactComponent as QuestionMark } from '../../../assets/icons/question-mark.svg';

interface IconProps extends TestId {
  asset: AssetWithMetadata;
  iconClassName?: string;
  size?: TokenIconSize;
  isCompact: boolean;
  i: number;
  isPair: boolean;
  type: TokenIconType;
}

export interface AssetWithMetadata extends Partial<Asset> {
  url: string | null;
  symbol: string;
  iconAvailable: boolean;
}

export const SIZES_MAP = {
  xxs: {
    width: 16,
  },
  xs: {
    width: 20,
  },
  s: {
    width: 24,
  },
  m: {
    width: 28,
  },
  l: {
    width: 40,
  },
  xl: {
    width: 56,
  },
};

export const getIconOverlapSize = (size: TokenIconSize, isCompact: boolean) => {
  switch (true) {
    case isCompact:
      return SIZES_MAP[size].width / 7;

    default:
      return SIZES_MAP[size].width / 6;
  }
};

export const Icon = ({
  iconClassName,
  size = 'm',
  'data-testid': testId = 'icon',
  isCompact,
  asset,
  i,
  type,
  isPair,
}: IconProps) => {
  const { origin, url } = asset;
  const width = SIZES_MAP[size].width;
  const isDiagonalPair = isPair && type === 'diagonal';
  const showOrigin = asset.type !== AssetType.LP && origin && !isDiagonalPair;
  const maskId = uniqueId('origin-mask');

  const getIconSizeStyle = () => {
    if (isPair && isCompact) {
      return {
        width: width,
        maxWidth: width,
      };
    }

    return {
      minWidth: width,
      maxWidth: width,
    };
  };

  return (
    <svg
      viewBox="0 0 120 105"
      className={cls(iconClassName)}
      style={{
        transform: `translateX(-${getIconOverlapSize(size, isCompact) * i}px) ${
          isPair ? 'scale(1.3)' : ''
        }`,
        paddingBottom: isDiagonalPair && i === 0 ? '16%' : undefined,
        paddingTop: isDiagonalPair && i === 1 ? '16%' : undefined,
        zIndex: 5 - i,
        ...getIconSizeStyle(),
      }}
      data-testid={`${testId}-${asset.symbol}`}
    >
      {showOrigin && (
        <>
          <mask id={maskId}>
            <rect x="0" y="0" width="100" height="100" fill="white" rx="50" ry="50" />
            <circle cx="92" cy="80" r="28" fill="black" />
          </mask>
          {isPair && i === 0 && <circle cx="92" cy="80" r="28" className="fill-default" />}
          <image
            href={`https://storage.googleapis.com/gasp-assets/channels/${origin}.png`}
            x="70"
            y="57"
            width="46"
            height="46"
            fill="black"
          />
        </>
      )}
      {url ? (
        <image
          href={url}
          x="0"
          y="0"
          width="100"
          height="100"
          data-testid={`${testId}-svg`}
          mask={showOrigin ? `url(#${maskId})` : undefined}
        />
      ) : (
        <svg
          width="100"
          height="100"
          mask={showOrigin ? `url(#${maskId})` : undefined}
          data-testid={`${testId}-svg`}
        >
          <circle cx="50" cy="50" r="50" className="fill-accent-soft" />
          <svg
            width="70"
            height="70"
            x="20"
            y="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <QuestionMark className="fill-secondary" />
          </svg>
        </svg>
      )}
    </svg>
  );
};
