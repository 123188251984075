import { captureMessage } from '@sentry/react';
import { Query } from '@tanstack/react-query';

const IGNORED_QUERY_KEYS = [
  'token-price',
  'coingecko-token-list',
  'coingecko-token-price',
  'coingecko-contract-info',
  'pool-tvl-history',
  'pool-volume-history',
  'pair-price-history',
  'single-price-history',
];
const IGNORED_ERRORS = [
  'Network Error',
  'WebSocket is not connected',
  'Error: disconnected',
  'Failed to fetch',
];

export const globalQueryErrorHandler = (
  error: unknown,
  query: Query<unknown, unknown, unknown>,
) => {
  const queryKey = query.queryKey[0]?.toString();

  if (
    !queryKey ||
    IGNORED_QUERY_KEYS.includes(queryKey) ||
    IGNORED_ERRORS.map((errStr) => error?.toString().includes(errStr)).some(Boolean)
  ) {
    return;
  }

  captureMessage(`[${queryKey}] - ${error?.toString()}`, {
    extra: {
      queryKey: query.queryKey,
      error: error,
    },
    level: 'warning',
  });
};
