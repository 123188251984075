import { QueryOptional } from '../../../../../../services';
import { Asset, transformToAsset } from '../../../../../token';
import { TxType } from '../../../../../transaction';
import { RollupToken } from '../../../RollupStash';
import { TxTrackingListStatusFilter } from '../query/useTxTrackingListQuery';
import {
  TxTrackingByAddressResponse,
  TxTrackingByAddressItem,
  TxTrackingStatus,
} from '../services/txTrackingListService';
import Decimal from 'decimal.js';

const PENDING_STATUSES = [
  TxTrackingStatus.Initiated,
  TxTrackingStatus.Pending_L1,
  TxTrackingStatus.Submitted_L2,
  TxTrackingStatus.Pending_L2,
  TxTrackingStatus.Batched_L1,
];

export type TxTrackingRecord = Omit<TxTrackingByAddressItem, 'created' | 'updated' | 'type'> & {
  asset: Asset;
  created: Date;
  updated: Date;
  type: TxType;
};

export const transformTxTrackingList =
  (
    rollupTokens: QueryOptional<RollupToken[]>,
    customAssets: Asset[],
    statusFilter?: TxTrackingListStatusFilter,
  ) =>
  (data: TxTrackingByAddressResponse['transactions'] | null) => {
    if (!data || !rollupTokens) {
      return null;
    }

    return data
      ?.reduce((acc: TxTrackingRecord[] | null, tx) => {
        const rollupToken = rollupTokens.find((t) => t.source.address === tx.asset_address) || null;

        let token: Asset | RollupToken | null = rollupToken;

        if (!token) {
          const customAsset =
            customAssets.find((t) => t.source?.address === tx.asset_address) || null;

          if (!customAsset) {
            return acc;
          }

          token = customAsset;
        }

        if (!token) {
          return acc;
        }

        const asset = transformToAsset(token);
        tx.amount = tx.amount.replace(/,/g, '');

        const item = {
          ...tx,
          asset,
          type: tx.type === 'deposit' ? TxType.RollupDeposit : TxType.RollupWithdrawal,
          created: new Date(tx.created),
          updated: new Date(tx.updated),
          amount: new Decimal(`${tx.amount}e-${asset.decimals}`).toFixed(),
        };

        if (
          statusFilter === TxTrackingListStatusFilter.All ||
          (statusFilter === TxTrackingListStatusFilter.Pending &&
            PENDING_STATUSES.includes(tx.status)) ||
          (statusFilter === TxTrackingListStatusFilter.Processed &&
            tx.status === TxTrackingStatus.Processed)
        ) {
          return acc ? [...acc, item] : [item];
        }

        return acc;
      }, [])
      ?.sort((a, b) => b.created.getTime() - a.created.getTime());
  };
