import { Container, Text } from 'ui';
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg';
import { ReactComponent as UniswapLogo } from '../assets/uniswap.svg';
import { EnvConfig } from 'core';

const NON_TRADABILITY_LINK = 'https://blog.gasp.xyz/the-gasp-alpha-launch/';

export const NativeTokenWidgetGetGasp = () => {
  const uniswapWidget = (
    <a href={EnvConfig.TOKEN_BUY_URL} target="_blank" rel="noopener noreferrer">
      <Container fullWidth className="mt-6 rounded-xl bg-soft hover:bg-hover p-4 transition-colors">
        <Container column className="mr-4" fullWidth>
          <Container fullWidth alignItems="center">
            <Text id="nativeTokenWidget.getGasp.uniswap.title" type="title-3" />
            <div className="ml-1">
              <UniswapLogo className="w-[90px] h-[25px] fill-neutral" />
            </div>
          </Container>
          <Text
            id="nativeTokenWidget.getGasp.uniswap.desc"
            color="secondary"
            className="mt-2 whitespace-pre-line"
          />
        </Container>
        <div>
          <ArrowRight className="stroke-secondary w-5 h-auto" />
        </div>
      </Container>
    </a>
  );

  return (
    <Container fullWidth column className="px-5 mt-2">
      <Text id="nativeTokenWidget.getGasp.title" type="title-2" />
      {uniswapWidget}

      <div className="text-center mt-5">
        <Text id="nativetokenWidget.getGasp.notice.prefix" type="label-bold" color="secondary" />
        <Text id="nativetokenWidget.getGasp.notice.content" color="secondary" className="mx-1" />
        <a href={NON_TRADABILITY_LINK} rel="noopener noreferrer" target="_blank">
          <Text
            id="nativetokenWidget.getGasp.notice.suffix"
            color="secondary"
            className="underline"
          />
        </a>
      </div>
    </Container>
  );
};
