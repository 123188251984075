import * as Sentry from '@sentry/react';
import { EnvConfig } from '../../../envConfig';

export function initSentry(dsn: string) {
  const environment = EnvConfig.SENTRY_ENV;

  if (dsn === '' || environment === '') {
    return;
  }

  const integrations = [
    Sentry.browserTracingIntegration(),
    Sentry.extraErrorDataIntegration({ depth: 5 }),
  ];

  Sentry.init({
    dsn,
    environment,
    integrations,
    tracesSampleRate: 1,
    release: EnvConfig.APP_VERSION,
    normalizeDepth: 5,
  });
}

export enum SentryTag {
  WalletExtension = 'wallet.extension',
  UserAddress = 'user.address',
  UserAccountType = 'user.account-type',
}
