import { Container, Text } from 'ui';
import stakingImg from '../assets/staking.png';
import stakingBg from '../assets/staking-bg.png';
import { Link } from 'react-router-dom';

export const NativeTokenWidgetStaking = () => {
  return (
    <Container className="px-5 py-2">
      <Link to="/staking">
        <Container
          fullWidth
          alignItems="center"
          className="px-5 py-2 rounded-xl relative bg-accent"
        >
          <Text type="title-3" color="inverted" id="nativeTokenWidget.staking.title" />
          <img src={stakingImg} alt="" className="w-[60px] h-auto z-10" />
          <img src={stakingBg} alt="" className="absolute top-0 right-0 z-0" />
          <div className="absolute right-0 top-0 bottom-0 z-10 w-1/2 opacity-30 bg-gradient-to-l from-default from-0% to-100% to-transparent" />
        </Container>
      </Link>
    </Container>
  );
};
