import Decimal from 'decimal.js';
import { fromBN } from 'gasp-sdk';
import { UseQueryResult } from '@tanstack/react-query';
import { PoolWithRatio } from '../../../Pool';
import { EnvConfig } from '../../../../../envConfig';

export interface PromotedPoolAprProps {
  prices: Record<string, UseQueryResult<string | null>>;
  pool: PoolWithRatio | undefined;
  annualRewards: string | undefined;
}

const NATIVE_TOKEN_ID = EnvConfig.TOKEN_ID;
const FIXED_PLACES = 0;

export const getPromotedPoolApr = ({ pool, prices, annualRewards }: PromotedPoolAprProps) => {
  if (!pool || !annualRewards) {
    return null;
  }

  const annualNativeRewards = new Decimal(annualRewards);

  if (pool.firstTokenId === NATIVE_TOKEN_ID || pool.secondTokenId === NATIVE_TOKEN_ID) {
    const isFirstNative = pool.firstTokenId === NATIVE_TOKEN_ID;
    const burnAmount = isFirstNative ? pool.firstTokenAmount : pool.secondTokenAmount;

    if (!burnAmount.eqn(0)) {
      return annualNativeRewards
        .div(
          new Decimal(
            fromBN(
              burnAmount,
              isFirstNative ? pool.firstAsset.decimals : pool.secondAsset.decimals,
            ),
          ).mul(2),
        )
        .mul(100)
        .toFixed(FIXED_PLACES);
    }
  }

  const nativeTokenPrice = prices[NATIVE_TOKEN_ID]?.data;

  if (nativeTokenPrice) {
    const annualRewardsInUSD = annualNativeRewards.mul(nativeTokenPrice);
    const firstTokenPrice = prices[pool.firstAsset.id]?.data;

    if (firstTokenPrice && firstTokenPrice !== '0') {
      if (!pool.firstTokenAmount.eqn(0)) {
        const poolActivatedTvlInUSD = new Decimal(
          fromBN(pool.firstTokenAmount, pool.firstAsset.decimals),
        )
          .mul(2)
          .mul(firstTokenPrice);

        if (poolActivatedTvlInUSD.gt(0)) {
          return annualRewardsInUSD.div(poolActivatedTvlInUSD).mul(100).toFixed(FIXED_PLACES);
        }
      }
    } else {
      const secondTokenPrice = prices[pool.secondAsset.symbol]?.data;
      if (secondTokenPrice && secondTokenPrice !== '0') {
        if (!pool.secondTokenAmount.eqn(0)) {
          const poolActivatedTvlInUSD = new Decimal(
            fromBN(pool.secondTokenAmount, pool.secondAsset.decimals),
          )
            .mul(2)
            .mul(secondTokenPrice);

          if (poolActivatedTvlInUSD.gt(0)) {
            return annualRewardsInUSD.div(poolActivatedTvlInUSD).mul(100).toFixed(FIXED_PLACES);
          }
        }
      }
    }
  }

  return '0';
};
